@import 'scss/reset';

/* Variables */
$primary-blue: #1CB7FC;
$light-blue: #CEF0FF;
$medium-blue: #2E415E;
$primary-dark: #1E293A;
$blue-hover: #188FC3;
$medium-hover: #243349;
$footer: #171F2C;
$white: #fff;
$title: #334154; 
$gray-500-p: #64758A;
$gray-600-heading: #475568;
$border: #e2e2e2;

/* Default */
.debug {
    height: 500px;
}

.container { 
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    transition: padding .2s ease;

    &-small {
        max-width: 1024px;
        margin: 0 auto;
        padding: 0 40px;
        transition: padding .2s ease;
    }
}

.title {
    font-size: 3.5em;
    font-weight: 600;
    color: $title;
    line-height: 1.25;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: font-size .2s ease;

    &--center {
        text-align: center;
    }

    &--white {
        color: $white;
    }
}

.subtitle {
    display: inline-block;
    font-size: 1.1em;
    color: $primary-blue;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.25;
    padding: 5px 10px;
    border-radius: 5px;
    transition: font-size .2s ease;

    &--center {
        text-align: center;
    }

    &--light {
        background-color: $light-blue;
    }

    &--medium {
        background-color: $medium-blue;
    }
}

.smalltitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.25;
    color: $gray-600-heading;

    &--center {
        text-align: center;
    }

    &--white {
        color: $white;
    }
}

.articletitle {
    font-size: 2.3em;
    font-weight: 600;
    color: $title;
    line-height: 1.25;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: font-size .2s ease;

    &--center {
        text-align: center;
    }

    &--white {
        color: $white;
    }

    &--margin {
        margin-top: 40px;
    }
}

.desc {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: $gray-500-p;
    line-height: 1.25;
    transition: font-size .2s ease;

    &--white {
        color: $white;
    }
}

.ul {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: $gray-500-p;
    line-height: 1.25;
    transition: font-size .2s ease;
}

.link {
    font-size: 1.2;
    text-decoration: none;
    color: $primary-blue;
    transition: color .2s ease;

    &:hover {
        color: $blue-hover;
    }
}

.btn {
    position: relative;
    top: 0;
    display: inline-block;
    border: 2px solid $primary-blue;
    padding: 15px 25px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: $primary-blue;
    color: $white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
    transition: .2s background-color ease, .2s border ease, .2s top ease, .2s box-shadow ease;

    &:hover {
        top: -4px;
        background-color: $blue-hover;
        border: 2px solid $blue-hover;
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.4); 
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.4);
    }

    &--margin {
        margin-top: 10px;
    }
}

.cookiebtn {
    position: relative;
    top: 0;
    display: inline-block;
    border: 2px solid $primary-blue;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    margin: 10px;
    background-color: $primary-blue;
    color: $white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: .2s background-color ease, .2s border ease;

    &:hover {
        background-color: $blue-hover;
        border: 2px solid $blue-hover;
    }
}

svg {
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: $primary-blue;
}

/* Header Component */
.header {
    background-color: rgba(15, 23, 41, 0);
    padding: 20px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    transition: padding .2s ease, background-color .2s ease;

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.logo {
    max-width: 150px;
    transition: max-width .2s ease;

    &__img {
        width: 100%;
        height: 100%;
    }
}

.nav {
    color: $white;
    font-weight: 600;

    &__list {
        display: flex;
        align-items: center;
    }

    &__item {
        margin-left: 20px;
        text-decoration: none;
        color: inherit;
        transition: .2s color ease;

        &:hover {
            color: $primary-blue;
        }
        
        // a {
        //     text-decoration: none;
        //     color: inherit;
        //     transition: .2s color ease;

        //     &:hover {
        //         color: $primary-blue;
        //     }
        // }

        &--active {
            color: $primary-blue;
        }

        &--btn {
            border: 2px solid $primary-blue;
            padding: 10px 20px;
            border-radius: 5px;
            transition: .2s background-color ease;
            cursor: pointer;

            &:hover {
                background-color: $primary-blue;
                color: $white;

                // a {
                //     color: $white;
                // }
            }

            &.nav__item--active {
                background-color: $primary-blue;
                color: $white;

                // a {
                //     color: $white;
                // }
            }
        }
    }
}

.mobile-nav {
    display: none;
    position: fixed;
    top: 25px;
    right: 56px;
    z-index: 1;
    transition: top .2s ease;

    a {
        color: $white;
        text-decoration: none;
    }
}

.mobile-nav .checkbox-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 45px;
    height: 52px;
    opacity: 0;
}

.mobile-nav .checkbox-toggle:checked + .hamburger > div {
    transform: rotate(135deg);
}

.mobile-nav .checkbox-toggle:checked + .hamburger > div:before, .mobile-nav .checkbox-toggle:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
}

.mobile-nav .checkbox-toggle:checked + .hamburger > div:after {
    opacity: 0;
}

.mobile-nav .checkbox-toggle:checked ~ .menu {
    pointer-events: auto;
    visibility: visible;
}

.mobile-nav .checkbox-toggle:checked ~ .menu > div {
    transform: scale(1);
    transition-duration: 0.75s;
}

.mobile-nav .checkbox-toggle:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.4s ease 0.4s;
}

.mobile-nav .checkbox-toggle:checked:hover + .hamburger > div {
    transform: rotate(225deg);
}

.mobile-nav .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 20px;
    height: 42px;
    padding: 0.5em 1em;
    border-radius: 0 0.12em 0.12em 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-nav .hamburger > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: #FEFEFE;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-nav .hamburger > div:before, .mobile-nav .hamburger > div:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 0;
    width: 100%;
    height: 2px;
    background: inherit;
    transition: all 0.4s ease;
}

.mobile-nav .hamburger > div:after {
    top: 8px;
}

.mobile-nav .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-nav .menu > div {
    width: 200vw;
    height: 200vw;
    color: #FEFEFE;
    background: $primary-blue;
    border-radius: 50%;
    transition: all 0.4s ease;
    flex: none;
    transform: scale(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-nav .menu > div > div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.4s ease;
    overflow-y: auto;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-nav .menu > div > div > ul {
    list-style: none;
    padding: 0 1em;
    margin: 0;
    display: block;
    max-height: 100vh;
}

.mobile-nav .menu > div > div > ul > li {
    padding: 0;
    margin: 1em;
    font-size: 24px;
    display: block;
}

.mobile-nav .menu > div > div > ul > li > a {
    position: relative;
    display: inline;
    cursor: pointer;
    transition: color 0.4s ease;
}

.mobile-nav .menu > div > div > ul > li > a:hover {
    color: $light-blue;
}

// .mobile-nav .menu > div > div > ul > li > a:hover:after {
//     width: 100%;
// }

.mobile-nav .menu > div > div > ul > li > a:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -0.15em;
    left: 0;
    width: 0;
    height: 2px;
    background: #e5e5e5;
    transition: width 0.4s ease;
}

/* Banner Component */
.banner {
    background-image: url(./img/banner.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    transition: height .2s ease;

    &--small {
        height: 500px;
    }

    &__flex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        &--left {
            align-items: flex-start;
        }
    }
}

.breadcrumb {
    font-size: 1.1em;

    li {
        display: inline;
        color: $white;
        font-weight: 400;

        + li:before {
            padding: 0 5px 0 10px;
            color: $gray-500-p;
            content: "/\00a0";
        }

        > a {
            color: $primary-blue;
            text-decoration: none;
            transition: .2s color ease;

            &:hover {
                color: $blue-hover;
                text-decoration: none;
            }
        }
    }
}

/* MethodGrid Component */
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    &--top {
        margin-top: -100px;
    }

    &--double {
        padding-top: 40px;
        padding-bottom: 160px;
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        background-color: $white;
        padding: 20px;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    }

    &__figure {
        width: 90px;
        height: 90px;
        margin: 0 auto;

        &--left {
            margin: 0;
        }
    }

    &__spacer {
        padding-top: 10px;
        padding-bottom: 20px;
    }

    &__img {
        width: 100%;
        height: 100%;
    }
}

/* Method Component */
.method {
    padding-top: 160px;
    padding-bottom: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__list {
        margin-top: 20px;
        margin-bottom: 20px;
        color: $gray-500-p;
        font-weight: 500;
        font-size: 1.2em;
        transition: font-size .2s ease;
    }

    &__content {
        padding-right: 40px;
        width: 100%;
    }

    &__item {
        margin-bottom: 15px;
    }

    &__figure {
        max-width: 400px;
    }

    &__img {
        width: 100%;
        height: 100%;
    }
}

/* RecentlyDelivered Component */
.recent-opgeleverd {
    padding-top: 160px;
    padding-bottom: 160px;
    background-color: $primary-dark;

    &__header {
        width: 100%;
        text-align: center;
    }

    &__footer {
        padding-top: 100px;
        width: 100%;
        text-align: center;
    }
}

.case-grid {
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
    &--nopadding {
        padding-top: 0;
    }

    &__figure {
        width: 100%;
        height: 220px;
        margin: 0 auto;
    }
    
    &__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

/* Info Component */
.info {
    padding-top: 160px;
    padding-bottom: 160px;

    &__header {
        width: 100%;
        text-align: center;
    }

    &__figure {
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 1000px;
        margin: 0 auto;
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        flex: 1;
        padding-right: 40px;
    }

    &__list {
        margin-top: 20px;
        margin-bottom: 20px;
        color: $gray-500-p;
        font-weight: 500;
        font-size: 1.2em;
        transition: font-size .2s ease;
    }

    &__item {
        margin-bottom: 15px;
    }
}

/* Alert Component */
.alert {
    padding-top: 160px;
    padding-bottom: 160px;
    background-color: $primary-dark;
}

/* Terms Component */
.terms {
    padding-top: 160px;
    padding-bottom: 160px;

    &__header {
        width: 100%;
        text-align: center;
    }
}

/* Footer Component */
.page-footer {
    padding-top: 120px;
    background-color: $footer;

    span {
        padding-top: 80px;
        padding-bottom: 40px;
        text-align: center;
        display: block;
        color: $white;
        font-weight: 600;
        line-height: 1.25;

        a {
            color: $primary-blue;
            text-decoration: none;
            transition: .2s color ease;
            
            &:hover {
                color: $blue-hover;
            }
        }
    }
}

.footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    &__list {

        & li > a {
            text-decoration: none;
            color: $white;
            transition: .2s padding ease;

            &:hover {
                padding-left: 5px;
            }
        }
    }

    &__list-item {
        line-height: 1.5;
    }
}

.socials {
    display: flex;
    padding-top: 40px;

    &__figure {
        position: relative;
        top: 0;
        width: 25px;
        height: 25px;
        background-color: $medium-hover;
        padding: 10px;
        border-radius: 5px;
        margin-right: 10px;
        transition: .2s top ease, .2s background-color ease;

        &:hover {
            top: -2px;
            background-color: $medium-blue;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
    }
}

/* Cases Component */
.cases {
    padding-top: 160px;
    padding-bottom: 160px;

    &__header {
        width: 100%;
        text-align: center;

        .desc {
            max-width: 1024px;
            margin: 0 auto;
            text-align: left;
            margin-bottom: 40px;
        }
    }

    &__footer {
        padding-top: 100px;
        width: 100%;
        text-align: center;
    }
}

/* Preface Component */
.preface {
    padding-top: 160px;

    &__header {
        width: 100%;
        text-align: center;

        .desc {
            text-align: left;
        }
    }
}

/* Collapsible Component */
.collapsible {
    display: grid;
    grid-gap: 80px;
    gap: 80px;
    align-items: center;
    grid-template-columns: repeat(2,minmax(0,1fr));
    padding-bottom: 160px;
    
    &__header {
        padding-top: 160px;
        width: 100%;
        // text-align: center;
        padding-bottom: 40px;
    }

    &__image {
        background-image: url(./img/katwijk.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 500px;
    }
}

.accordion {

    .accordion-item {
        border-bottom: 1px solid $border;

        button[aria-expanded='true'] {
            border-bottom: 1px solid $primary-blue;
        }
    }

    button {
        position: relative;
        display: block;
        text-align: left;
        width: 100%;
        padding: 20px 0;
        // padding: 1em 0;
        color: $gray-600-heading;
        font-size: 1.2em;
        font-weight: 600;
        border: none;
        background: none;
        outline: none;
        transition: .2s color ease, transition, .2s font-size ease;

        &:hover {
            cursor: pointer;
            color: $primary-blue;
        
            &::after {
                cursor: pointer;
                color: $primary-blue;
                border: 1px solid $primary-blue;
            }
        }

        .accordion-title {
            position: relative;
            top: 5px;
            padding: 1em 1.5em 1em 0;
        }

        .icon {
            // display: inline-block;
            // position: absolute;
            // top: 18px;
            // right: 0;
            // width: 22px;
            // height: 22px;
            // border: 1px solid;
            // border-radius: 22px;
            color: $primary-blue;
            margin-right: 20px;

            // horizontaal -
            &::before {
                display: inline-block;
                // position: absolute;
                content: '';
                // top: 9px;
                // left: 5px;
                width: 12px;
                height: 2px;
                background: currentColor;
            }
        
            // verticaal |
            &::after {
                display: inline-block;
                position: absolute;
                top: 30px;
                left: 5px;
                content: '';
                width: 2px;
                height: 12px;
                background: currentColor;
            }
        }
    }
    
    button[aria-expanded='true'] {
        color: $primary-blue;

        .icon {

            &::after {
                width: 0;
            }
        }

        + .accordion-content {
            opacity: 1;
            max-height: 9em;
            transition: all 200ms linear;
            will-change: opacity, max-height;
            color: $gray-500-p;
        }
    }
    
    .accordion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        padding-left: 32px;
        transition: opacity 200ms linear, max-height 200ms linear;
        will-change: opacity, max-height;

        p {
            font-size: 1.2em;
            font-weight: 300;
            margin: 2em 0;
            transition: font-size .2s ease;
        }
    }
}

/* WebsiteColumns Component */
.column-grid {
    display: grid;
    grid-gap: 80px;
    gap: 80px;
    padding-top: 40px;
    padding-bottom: 160px;
    grid-template-columns: repeat(2,minmax(0,1fr));
}

/* AppTypes Component */
.app-types {
    padding-top: 160px;
    width: 100%;
    text-align: center;
}

.app-types__flex {
    display: flex;
    flex-direction: column;
}

.app-types__item {
    margin-top: 10px;
    background-color: $white;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.app-types__figure {
    margin: 0 auto;
    max-width: 90px;
    max-height: 90px;
}

.app-types__img {
    width: 100%;
    height: 100%;
}

/* Responsive */
@media screen and (max-width: 1024px) {
    /* Default */
    .desc, .ul {
        font-size: 1.1em;
        line-height: 1.5;
    }

    /* Banner Component */
    .banner {
        background-attachment: inherit;
    }
    
    /* Method Component */
    .method {
        flex-direction: column;

        &__list {
            font-size: 1.1em;
        }

        &__content {
            margin-bottom: 40px;
            padding-right: 0;
        }
    }

    /* Info Component */
    .info {
        flex-direction: column;

        &__list {
            font-size: 1.1em;
        }
    }

    /* Collapsible Component */
    .collapsible {
        grid-gap: 40px;
        gap: 40px;
        grid-template-columns: repeat(1,minmax(0,1fr));
    
        &__image {
            height: 200px;
        }
    }

    .accordion { 

        button { 
            font-size: 1.1em;

            .icon {

                &::after {
                    top: 29px;
                }
            }
        }

        .accordion-content {
    
            p {
                font-size: 1.1em;
            }
        }
    }

    /* Footer Component */
    .footer {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 768px) {
    /* Default */
    .container, .container-small {
        padding: 0 20px;
    }

    .title {
        font-size: 3em;
    }

    .articletitle {
        font-size: 2em;
    }

    /* Header Component */
    .nav {
        display: none
    }

    .mobile-nav {
        display: block;
    }

    /* MethodGrid / RecentlyDelivered Component */
    .grid, .case-grid {
        grid-template-columns: 1fr 1fr;
    }

    .grid {

        &--double {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    /* Info Component */
    .info {
        
        &__flex {
            flex-direction: column;
            align-items: flex-start;
        }

        &__content {
            padding-right: 0;
        }
    }

    /* WebsiteColumns Component */
    .column-grid {
        grid-gap: 40px;
        gap: 40px;
        grid-template-columns: repeat(1,minmax(0,1fr));
    }

    /* Footer Component */
    .footer {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 425px) {
    /* Default */
    .title {
        font-size: 2.2em;
    }

    .subtitle {
        font-size: 1em;
    }

    .desc, .ul {
        font-size: 1em;
    }

    .articletitle {
        font-size: 1.6em;
    }

    /* Banner Component */
    .banner {
        height: 650px;
    }

    /* MethodGrid / RecentlyDelivered Component */
    .grid, .case-grid {
        grid-template-columns: 1fr;
    }

    /* Method Component */
    .method {

        &__list {
            font-size: 1em;
        }
    }

    /* Info Component */
    .info {

        &__list {
            font-size: 1em;
        }
    }

    /* Collapsible Component */

    .accordion { 

        button { 
            font-size: 1em;

            .icon {
                margin-right: 0;
                position: absolute;
                right: 10px;
    
                &::after {
                    top: 7px;
                }
            }
        }

        .accordion-content {
    
            p {
                font-size: 1em;
            }
        }
    }

    /* Footer Component */
    .footer {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 375px) {
    /* Default */
    .container, .container-small {
        padding: 0 10px;
    }

    /* Grid Component */
    .grid {
    
        &__figure {
            max-width: 90px;
            max-height: 90px;
        }
    }
}